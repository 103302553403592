/* stylelint-disable declaration-no-important */

@import './variables.css';

/* @import './animations.css'; */

html,
body {
  height: 100%;
  width: 100%;
}

.inset-abs-x-y-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.inset-abs-x-center {
  left: 50%;
  transform: translate(-50%, 0);
}

.inset-abs-y-center {
  top: 50%;
  transform: translate(0, -50%);
}

.h-min-screen {
  min-height: 100%;
  min-height: 100vh;
}

/* .h-half {
  min-height: calc(100vh - 50%);
} */

.h-min-100-height-without-header {
  min-height: calc(100vh - 56px);
}

.modal-wrapper {
  z-index: 9999;
}

.bg-blur {
  backdrop-filter: saturate(180%) blur(20px);
}

.bg-blur-5 {
  backdrop-filter: saturate(180%) blur(5px);
}

.bg-blur-10 {
  backdrop-filter: saturate(180%) blur(10px);
}

.button {
  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.loading {
    @apply relative text-transparent !important;

    .ring-loader-container {
      @apply visible opacity-100 !important;
    }
  }
}

.top-100 {
  top: 100%;
}

.left-100 {
  left: 100%;
}

.right-100 {
  right: 100%;
}

.bottom-100 {
  bottom: 100%;
}

.opacity-90 {
  opacity: 0.9;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* The container */
.containerCB {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCB input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.containerCB:hover input ~ .checkmark {
  background-color: #a2bafa;
}

/* When the checkbox is checked, add a blue background */
.containerCB input:checked ~ .checkmark {
  background-color: rgb(30, 58, 138);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCB input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCB .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}